








































import { Vue, Component } from 'vue-property-decorator'
import { namespace, Action, State } from 'vuex-class'
import { getMiniProgramPath } from '@/utils/mpFormatter'
import { IMiniAppItem } from '@/types/common.d.ts'
import { mapActions } from 'vuex'
@Component({
	methods: {
		...mapActions([
			'fetchMiniPrograms'
		])
	}
})
export default class MiniprogramPath extends Vue{
	public fetchMiniPrograms!: (params: {limit: number, skip: number}) => Promise<any>
	private form: any = {
		h5Link: '',
		shopName: ''
	}
	private mpPath: string = ''
	private showResult: boolean = false
	private qrcode: string = ''

	@State(state => state.deployment.mpList) mpList: any

	submit () {
		(this.$refs.form as any).validate().then(async (res: Boolean) => {
			if (res) {
				this.mpPath = getMiniProgramPath(this.form.h5Link)
				await this.fetchMiniPrograms({limit: 1000, skip: 0})
				const mp = this.mpList.find((item: IMiniAppItem) => item.project.shopName == this.form.shopName)
				if (mp) {
					this.$store.dispatch('generateQRCode', {path: this.mpPath, projectId: mp.projectId}).then(res => {
						this.qrcode = `data:image/jpeg;base64,${res.QRCode.imageBase64String}`
					})
				}
				this.showResult = true
			}
		})
	}
}
