const pathMap: any = {
	products: 'pages/product/list/main',
	productsShow: 'pages/product/show/main',
	home: 'pages/index/main',
	pagesShow: 'pages/page/show/main',
	collectionsShow: 'pages/collection/show/main',
	couponsRedeem: 'pages/coupons/redeem/main'
}

export function getMiniProgramPath (url: string): string {
	if (!url) return ''
	const [urlPart, queryPart] = url.split('?')
	const urlObj: any = urlPart.split('/')
	let miniappPath = ''
	if (urlObj) {
		if (urlObj[5] && urlObj[6] && urlObj[7]) {
			miniappPath = pathMap[urlObj[5] + 'Redeem'] ? pathMap[urlObj[5] + 'Redeem'] + `?id=${urlObj[6]}` : ''
		}
		else if (urlObj[5] && urlObj[6]) {
			miniappPath = pathMap[urlObj[5] + 'Show'] ? pathMap[urlObj[5] + 'Show'] + `?id=${urlObj[6]}` : ''
		} else if (urlObj[5] && !urlObj[6]) {
			miniappPath = pathMap[urlObj[5]] ? pathMap[urlObj[5]] : ''
		} else if (!urlObj[5] && !urlObj[6]) {
			miniappPath = pathMap['home']
		}
		if (queryPart) {
			miniappPath += `?${queryPart}`
		}
	}
	return miniappPath
}